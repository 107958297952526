<template>
  <div class="inner-news-services">
    <service-tile v-for="tile in data" :data="tile" v-bind:key="tile.id" />
  </div>
</template>

<script>
import ServiceTile from "@/components/page/services/tiles/ServiceTile";
export default {
  name: "ServiceTiles",
  components: { ServiceTile },
  props: {
    data: Array
  }
};
</script>

<style scoped></style>
