<template>
  <div :class="[blockClass, 'wow', 'fadeInUp']">
    <router-link
      :to="{ name: 'SingleService', params: { id: data.id } }"
      class="service-items-img"
    >
      <img :src="require('@/assets/img/' + data.cover)" alt="" />
    </router-link>
    <p class="name-service" v-html="data.name"></p>
    <router-link
      :to="{ name: 'SingleService', params: { id: data.id } }"
      class="service-item-price"
    >
      {{ data.price }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: "ServiceTile",
  props: {
    data: Object,
    blockClass: {
      type: String,
      default: "news-service-items-inner"
    }
  }
};
</script>

<style scoped></style>
