<template>
  <site-section section-class="news-mediateka-inner">
    <template v-slot:description>
      <service-tiles :data="services" />
      <div class="clear"></div>
    </template>
    <template v-slot:content>
      <a href="#" class="link-more-tovar-other wow fadeInUp">
        Показать еще
      </a>
    </template>
  </site-section>
</template>

<script>
import SiteSection from "@/components/page/SiteSection";
import ServiceTiles from "@/components/page/services/tiles/ServiceTiles";
export default {
  name: "ServiceBlock",
  components: { ServiceTiles, SiteSection },
  data() {
    return {
      services: [
        {
          id: 0,
          cover: "modul.png",
          name: "Интернет-банк для предпринимателей",
          price: "Бесплатно"
        },
        {
          id: 1,
          cover: "pw-1.png",
          name: "Сервис электронного документооборота",
          price: "От 990 руб/мес"
        },
        {
          id: 2,
          cover: "modul.png",
          name: "Интернет-банк для предпринимателей",
          price: "Бесплатно"
        },
        {
          id: 3,
          cover: "modul.png",
          name: "Интернет-банк для предпринимателей",
          price: "Бесплатно"
        },
        {
          id: 4,
          cover: "modul.png",
          name: "Интернет-банк для предпринимателей",
          price: "Бесплатно"
        },
        {
          id: 5,
          cover: "modul.png",
          name: "Интернет-банк для предпринимателей",
          price: "Бесплатно"
        },
        {
          id: 6,
          cover: "modul.png",
          name: "Интернет-банк для предпринимателей",
          price: "Бесплатно"
        },
        {
          id: 7,
          cover: "modul.png",
          name: "Интернет-банк для предпринимателей",
          price: "Бесплатно"
        },
        {
          id: 8,
          cover: "modul.png",
          name: "Интернет-банк для предпринимателей",
          price: "Бесплатно"
        },
        {
          id: 9,
          cover: "modul.png",
          name: "Интернет-банк для предпринимателей",
          price: "Бесплатно"
        },
        {
          id: 10,
          cover: "modul.png",
          name: "Интернет-банк для предпринимателей",
          price: "Бесплатно"
        },
        {
          id: 11,
          cover: "modul.png",
          name: "Интернет-банк для предпринимателей",
          price: "Бесплатно"
        }
      ]
    };
  }
};
</script>

<style scoped></style>
