<template>
  <site-section section-class="catalog-menu">
    <template v-slot:content>
      <ul class="catalog-menu-list" v-if="badges && badges.length">
        <li v-for="i in badges" v-bind:key="i.id">
          <search-tag
            type="radio"
            :checks.sync="selection"
            :tag="i.title"
            :val="i.id"
          />
        </li>
      </ul>
      <div class="clear"></div>
    </template>
  </site-section>
</template>

<script>
import SiteSection from "@/components/page/SiteSection";
import SearchTag from "@/components/page/items/search/SearchTag";
export default {
  name: "CatalogMenu",
  components: { SearchTag, SiteSection },
  props: {
    mainName: {
      type: String,
      default: "Вся медиатека"
    },
    categories: Array,
    selected: {
      type: [Array, Object, Number, String]
    }
  },
  computed: {
    badges: {
      get() {
        return [
          {
            id: 0,
            title: this.mainName
          }
        ].concat(this.categories);
      }
    },
    selection: {
      get() {
        return this.selected;
      },
      set(n) {
        this.$emit("update:selected", n);
      }
    }
  }
};
</script>

<style scoped></style>
