<template>
  <label>
    <input v-model="checking" :type="type" :value="val" />
    <span v-html="tag"></span>
  </label>
</template>

<script>
export default {
  name: "SearchTag",
  props: {
    tag: String,
    checks: {
      type: [Array, Object, Number, String]
    },
    val: {
      type: [String, Number, Object]
    },
    type: {
      type: String,
      default: "checkbox"
    }
  },
  computed: {
    checking: {
      get() {
        return this.checks;
      },
      set(n) {
        this.$emit("update:checks", n);
      }
    }
  }
};
</script>

<style scoped></style>
