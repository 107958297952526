<template>
  <div class="wrapper-inner">
    <div class="inner-content">
      <h1 class="header-big">Сервисы</h1>
      <catalog-menu main-name="Все сервисы" />
      <service-block />
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
import CatalogMenu from "@/components/page/menu/CatalogMenu";
import ServiceBlock from "@/components/page/services/ServiceBlock";
export default {
  name: "Services",
  components: { ServiceBlock, CatalogMenu },
  mounted() {
    new WOW({ offset: 140, live: false }).init();
  }
};
</script>

<style scoped></style>
